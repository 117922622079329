export default defineNuxtRouteMiddleware(async () => {
  // hotfix for nuxt generate running this code
  if (import.meta.server) {
    return;
  }

  const localePath = useLocalePath();
  const documentsStore = useDocumentsStore();

  const requiredAmendmentDocument = await documentsStore.getRequiredAmendmentDocument();
  if (requiredAmendmentDocument.DocumentType) {
    return await navigateTo(localePath('account-amendment'));
  }
});
